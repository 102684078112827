import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  description?: string
  index: number
}

export const Rule = memo(function Rule({ description, index }: Props) {
  if (!description) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      <Icon dial={5}>{index}</Icon>
      <Description>{description}</Description>
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 2.5rem;
`

const Icon = styled(FlexBox)`
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid ${({ theme }) => theme.colors.variants.primaryDark1};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin-right: 1.25rem;
  transform: translateY(-0.125rem);
`

const Description = styled.div`
  width: calc(100% - 3.75rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
`
