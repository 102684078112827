import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Rule } from './Rule'

export interface Props {
  image?: ImageProps
  rules: string[]
  title?: string
}

export const Rules = memo(function Rules({ image, rules, title }: Props) {
  if (rules.length < 1) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      </LeftSide>
      <RightSide dial={4}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <Wrapper row wrap>
          {rules.map((item, index) => (
            <Rule key={index} index={index + 1} {...item} />
          ))}
        </Wrapper>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding-top: 7.625rem;

  @media (max-width: 1023px) {
    padding-top: 5rem;
  }
`

const LeftSide = styled.div`
  width: 34%;
  padding-bottom: 45%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled(FlexBox)`
  width: 66%;
  padding: 0 12.708vw 5rem 9.028vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.875rem 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 2.5625rem;
`

const Wrapper = styled(FlexBox)`
  margin-top: 1.4375rem;

  @media (max-width: 1023px) {
    margin-top: 0;
  }
`
